* {
  box-sizing: border-box;
}
body {
  font-family: Rubik,sans-serif;
  font-size: 0.7rem;
  margin:0;
  padding: 0;
}
.title-border-simple {
  background-color: #005bea;
  height: 2px;
  width: 120px;
  align-items: center;
}
.title-border-colored {
  background-color: #ff8700;
  height: 6px;
  margin-top: -2px !important;
  width: 30px;
  border-radius: 50px;
  align-items: center;
  position: absolute;
}
h2 {
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 300 !important;
}
@media screen and (max-width: 600px) {
  h2, .title-border-simple {
    margin-left: 2rem;
  }
}