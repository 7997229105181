/* home section  */
#home {
    background-color: #0e6cff;
    background-image: url("http://zooki.react.themesbrand.com/static/media/home-2-bg.e74a5beee052680d051a.png");
    background-position: 50%;
    background-size: cover;
    height: 95vh;
    position: relative;
    font-family: Rubik, sans-serif;
    top: 0;
    display: flex;
    flex-grow: 1;
    align-items: center;
}

#home .wrapper {
    margin: auto auto auto auto;
    max-width: 1050px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));  /* Гнучкі колонки */
    align-items: center !important;
    justify-content: center;
    padding: 7rem 0;
}
#home .left,
#home .right {
    width: 100%;  /* Вся доступна ширина */
}

.groupBtn {
    display: flex;
    justify-content: space-around;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
}
#home .left {
    font-size: larger;
    color: #fff;
    overflow: auto;  /* Дозволяє прокрутку */
}

#home .right {
    display: flex;
    align-items: center;
    justify-content: center;
}

#home h1 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 400 !important;
    margin: 1rem 0;
}

#home p {
    line-height: 2;
    margin: 1.5rem 0;
}

#home img {
    width: 100%;
    height: auto;
    max-width: 28rem;
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-20px);
    }
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-20px);
    }
}

.btn {
    background-color: #ffbf00;
    padding: 0.5rem;
    width: 8rem;
    border-radius: 4px;
    font-size: 70%;
    text-align: center;
    margin: 0.5rem;
    /*color: #ffffff; !* Змініть колір на білий *!*/

}

#home a {
    text-transform: uppercase;
    color: #000000;
    text-decoration: none;
}

@media screen and (max-width: 600px) {
    #home .wrapper {
        grid-template-columns: 100%;
        width: 100%;
    }

    #home img {
        max-width: 20rem;
        height: auto;
    }

    #home .left {
        margin: -20px 1rem 1rem 1rem;
    }
}
