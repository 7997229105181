#pricing {
  height: 100%;
}
#pricing .wrapper {
  width: clamp(300px,100%, 1100px);
  margin: 4rem auto;
}
#pricing #container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  text-align: left;
  padding: 3rem 0;
}
.price-card {
  margin: 1rem;
  border: 1px solid #f3f3f3;
}
.price-card p {
  line-height: 1.8;
  color: grey;
}
.price-card a{
  text-decoration: none;
  background-color: #fff;
  color: #015be9;
  border: 2px solid #015be9;
  padding: 14px 25px;
  border-radius: 5px;
  margin: 1rem 0.7rem !important;
  text-transform: uppercase;
}
.price-card a:hover {
  background-color: #015be9;
  color: #fff;
  box-shadow: 0 3px 20px -8px #000;
}
.price-card .text {
  padding: 1.7rem 1rem 1rem 0.7rem;
  margin: 0rem 1rem;
}
#pricing .price-card:hover {
  border-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  -webkit-transform: scale(1.01);
  transform: scale(1.03);
  transition: all .5s;
}
.price-card:nth-child(2) {
  border-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  -webkit-transform: scale(1.01);
  transform: scale(1.05);
  transition: all .5s;
}
.price-card:nth-child(2) .card-top p, .price-card:nth-child(2) a {
  background-color: #015be9;
  color: #fff;
}
.price-card:nth-child(2) a {
  box-shadow: 0 3px 20px -8px #000;
}
.price-card .card-top {
  background-color: #f8f9fa;
}
.price-card .card-top p {
  margin: 0;
  padding: 1.7rem 1rem 1.7rem 1.5rem;
  font-size: 20px;
  color: #000;
}
.price-card .card-top strong {
  font-size: 28px;
}
.price-card .buy-btn {
  padding: 2rem 1rem;
}
#pricing .price-card:nth-child(2) .card-top::before {
  background-color: #015be9;
  border-bottom: 1px solid #015be9;
  border-right: 1px solid #015be9;
  bottom: 349px;
  top: 108;
  content: "";
  height: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all .5s;
  width: 20px;
}
@media screen and (max-width: 600px) {
  #pricing {
    height: auto;
  }
  #pricing #container {
    width:90%;
    grid-template-columns: 1fr;
    margin: 0 auto;
  }
}
/* General table styles */
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

/* Responsive styles */
@media screen and (max-width: 600px) {
  table, thead, tbody, th, td, tr {
    display: block;
  }

  th {
    display: none;
  }

  tr {
    margin-bottom: 15px;
  }

  td {
    display: flex;
    justify-content: space-between;
    padding-left: 50%;
    position: relative;
    text-align: right;
  }

  td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: bold;
    text-align: left;
  }
}