.header {
  background: transparent;
}
.topnav {
  border-radius: 0;
  transition: all .5s ease-in-out;
  z-index: 999;
  left: 0;
  position: fixed;
  right: 0;
  background: transparent;
  padding: 0 6rem;
  align-items: center;
}
.topnav a {
  font-size: large;
  float: right;
  display: block;
  color: #000000;
  text-align: left;
  padding: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  opacity: 0.7;
  margin: 0;
  height: 100%;
}
.topnav a:first-child {
  float: left;
  padding: 1rem 2rem;
}
.topnav a img {
  width: 5%;
  height: 5%;
}
.topnav a.active {
  text-decoration: underline;
  color: rgba(0, 0, 0, 0);
  text-decoration-thickness: 2px;
  text-underline-offset: 5px;
  opacity: 1;
}
.topnav a:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 5px;
  opacity: 1;
}
.topnav .icon {
  display: none;
}
.scroll {
  background-color: #ffffff;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
    zoom: 1.5;
  }
  .topnav {
    overflow: hidden;
    background-color: #ffffff;
    width: 100%;
    padding:0;
    color: rgb(0, 0, 0);
    transition: all .5s ease-in-out;
  } 
  .topnav.responsive {
    position: relative;
  }
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .topnav.responsive img {
    width: 35%;
    height: 20%;
  }
}