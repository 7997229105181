#feature {
    background-color: #efefef;
    padding: 2rem 0;
}

#feature .container {
    width: clamp(200px, 80%, 1100px);
    margin: 0 auto;
}

#feature h3 {
    font-size: 1.8em !important;
    font-weight: normal;
    color: #020202;
}

#feature p {
    font-size: 14px;
    color: #999;
    line-height: 1.5;
}

#feature .wrapper {
    display: flex;
    /*flex-flow: row-wrap;*/
    align-items: center;
}

#feature .text {
    margin: 2rem 2rem 2rem 0;
    align-items: center;
}

#feature .image {
    background-color: #005bea;
    margin: 2rem 2rem 2rem 0;
    align-items: center;
    border-radius: 50%;
    flex-basis: 60%;
}

#feature .icon {
    color: #0e6cff !important;
    font-size: 1.7rem;
    text-align: left;
    align-items: center;
    border: 1px solid #333;
    width: 20%;
    border-radius: 50%;
    padding: 0.2rem;
    border: 6px solid transparent;
}

#feature .head-icon {
    color: #0e6cff;
    zoom: 270%;
}

#feature .image img:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    transition: all .5s;
}

#feature .inner-image {
    position: relative;
    background-image: url('http://zooki.react.themesbrand.com/static/media/features-bg-effect.89b5be76ea9eec163deb.png');
    background-size: 29rem 29rem;
    background-position: 50%;
}

@media screen and (max-width: 600px) {
    #feaure {
        height: auto;
    }

    #feature .wrapper {
        flex-direction: column;
    }

    #feature .image, #feature .text {
        flex-basis: 100%;
    }

    #feature h2, #feature .title-border-simple, #feature .title-border-colored {
        margin-left: 0;
    }

    #feature img {
        width: 95% !important;
        margin: 0 auto;
    }

    #feature .image img:hover {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        transition: all .5s;
    }
}